import "./App.css";
import "./assets/Styles/style.css";
import "./assets/Styles/ScreenBoiler.css"
import "./assets/Styles/sideBar.css";
import "./assets/Styles/loginScreen.css";
import "./assets/Styles/AddCategory.css";
import "./assets/Styles/AddAndEditCategoryModal.css";
import "./assets/Styles/Pagination.css";
import "./assets/Styles/mainDashboard.css";
import "./assets/Styles/Users.css";
import "./assets/Styles/AddEvidenceModal.css";
import "./assets/Styles/imageComp.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Login from "./Screens/Login";
import ProtectedRoute from "./components/ProtectedRouter";
import StatesScreen from "./Screens/StatesScreen";
import Users from "./Screens/Users";
import PackagesScreen from "./Screens/PackagesScreen";
import CoursesScreen from "./Screens/CoursesScreen";
import SecurityQuestions from "./Screens/SecurityQuestions";
import LessonsScreen from "./Screens/LessonsScreen";
import QuestionsScreen from "./Screens/QuestionsScreen";
import TopicScreen from "./Screens/TopicScreen";
import ExamScreen from "./Screens/ExamScreen";
import DetailScreen from "./Screens/DetailScreen";
import ReAttemptionRequests from "./Screens/ReAttemptionRequests";
import ContactMessages from "./Screens/ContactMessages";
import Testimonials from "./Screens/Testimonials";
import Faq from "./Screens/Faq";
import Reports from "./Screens/Reports";
import CMSScreen from "./Screens/CMS";
import CMSDetail from "./Screens/CMSDetail";
import AfterExamQuestions from "./Screens/AfterExamQuestions";
import EvaluationQuestionsScreen from "./Screens/EvaluationQuestionsScreen";
import CoupanScreen from "./Screens/CouponScreen";
import Dashboard from "./Screens/Dashboard";
import CertificatesQuantityScreen from "./Screens/CertificatesQuantityScreen/CertificatesQuantityScreen";
import CertificatesScreen from "./Screens/CertificatesScreen";
import UpdatePasswordScreen from "./Screens/UpdatePasswordScreen";
import AppConfig from "./Screens/AppConfig";
import HeaderCmsPage from "./Screens/HeaderCmsPage";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Dashboard */}
          {/* <Route
            path="/"
            element={<ProtectedRoute component={<Dashbaord />} />}
          /> */}
          <Route
            path="/"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          {/* States */}
          <Route
            path="/state"
            element={<ProtectedRoute component={<StatesScreen />} />}
          />
          {/* Packages */}
          <Route
            path="/packages"
            element={<ProtectedRoute component={<PackagesScreen />} />}
          />
          {/* Courses */}
          <Route
            path="/courses"
            element={<ProtectedRoute component={<CoursesScreen />} />}
          />
          {/* lessons */}
          <Route
            path="/lessons"
            element={<ProtectedRoute component={<LessonsScreen />} />}
          />
          {/* Question */}
          <Route
            path="/question"
            element={<ProtectedRoute component={<QuestionsScreen />} />}
          />
          {/* Topics */}
          <Route
            path="/topic/:id"
            element={<ProtectedRoute component={<TopicScreen />} />}
          />
          {/* Detail */}
          <Route
            path="/detail/:id"
            element={<ProtectedRoute component={<DetailScreen />} />}
          />
          {/* Exam */}
          <Route
            path="/exam"
            element={<ProtectedRoute component={<ExamScreen />} />}
          />
          {/* security-questions */}
          <Route
            path="/security-questions"
            element={<ProtectedRoute component={<SecurityQuestions />} />}
          />
          {/* after-exam-questions */}
          <Route
            path="/after-exam-questions"
            element={<ProtectedRoute component={<AfterExamQuestions />} />}
          />
          {/* Users */}
          <Route
            path="/users"
            element={<ProtectedRoute component={<Users />} />}
          />
          <Route
            path="/requests"
            element={<ProtectedRoute component={<ReAttemptionRequests />} />}
          />
          <Route
            path="/contact"
            element={<ProtectedRoute component={<ContactMessages />} />}
          />
          {/* testimonial */}
          <Route
            path="/testimonial"
            element={<ProtectedRoute component={<Testimonials />} />}
          />
          {/* testimonial */}
          <Route path="/faq" element={<ProtectedRoute component={<Faq />} />} />

          {/* reports */}
          <Route
            path="/reports"
            element={<ProtectedRoute component={<Reports />} />}
          />
          {/* testimonial */}
          <Route
            path="/cms"
            element={<ProtectedRoute component={<CMSScreen />} />}
          />

          {/* content */}
          <Route
            path="/content"
            element={<ProtectedRoute component={<CMSDetail />} />}
          />
          <Route
            path="/content/header"
            element={<ProtectedRoute component={<HeaderCmsPage />} />}
          />
          {/* Evaluation Questions Screen */}
          <Route
            path="/evaluationQs"
            element={
              <ProtectedRoute component={<EvaluationQuestionsScreen />} />
            }
          />
          <Route
            path="/coupon"
            element={<ProtectedRoute component={<CoupanScreen />} />}
          />
          <Route
            path="/certificate"
            element={
              <ProtectedRoute component={<CertificatesScreen />} />
            }
          />
          <Route
            path="/update-password"
            element={
              <ProtectedRoute component={<UpdatePasswordScreen />} />
            }
          />
          <Route
            path="/app-config"
            element={
              <ProtectedRoute component={<AppConfig />} />
            }
          />

          {/* <ProtectedRoute path="/admins" exact component={<AdminLists />} />
        <ProtectedRoute
          path="/updatepassword"
          exact
          component={<UpdatePassword />}
        />
        <ProtectedRoute path="/CheckScreen" exact component={<CheckScreen />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
