import React, { useEffect, useState } from "react";
import ScreenBoiler from "../components/ScreenBoiler";
import { useSearchParams } from "react-router-dom";
import { apiHeader, BaseURL, imageUrl } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { Get, Post } from "../Axios/AxiosFunctions";
import { green } from "@mui/material/colors";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import TableRowsLoader from "../components/TableRowsLoader";

const HeaderCmsPage = () => {
  const [searchParams] = useSearchParams();
  const pageId = searchParams?.get("id");
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState(null);
  const [isGetting, setIsGetting] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);

  const [logoImage, setLogoImage] = useState(undefined);
  const [logoImageFile, setLogoImageFile] = useState(null);

  const [logo2Image, setLogo2Image] = useState(undefined);
  const [logo2ImageFile, setLogo2ImageFile] = useState(null);

  //   handleSelectImage
  const handleSelectImage = (event, setFile, setUrl) => {
    setFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setUrl(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  // get Item handler
  const getData = async () => {
    const url = BaseURL(`cms/page/header`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
      setLogoImage(response?.data?.data?.logo);
      setLogo2Image(response?.data?.data?.logoBlack);
    }
  };

  //   handleSubmit
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("logo", logoImageFile);
    formData.append("logoBlack", logo2ImageFile);
    formData.append("_id", pageId);
    formData.append("pageName", "header");

    const url = BaseURL(`cms/page/update`);
    setIsApiCall(true);
    const response = await Post(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);
    if (response !== undefined) {
      toast.success("Updated Successfully");
    }
  };

  // fire on component mount
  useEffect(() => {
    getData();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1 className="t-t-c">Header</h1>
        </div>

        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <>
            {/* Logo `1` */}
            <div className="col-md-5">
              <div class="form-group" className="seo__imagePicker mt-3">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  name="uploadfile"
                  id={"logo"}
                  style={{
                    display: "none",
                  }}
                  onChange={(event) => {
                    handleSelectImage(event, setLogoImageFile, setLogoImage);
                  }}
                />
                <label for="exampleInputEmail1">Home Page Logo (Black)</label>
                <label
                  for={"logo"}
                  className="btn btn-primary btn-lg btn-block"
                  style={{
                    backgroundColor: green[500],
                  }}
                  onChange={(e) => {
                    // console.log(e.target.files[0]);
                  }}
                >
                  {logoImage ? "Change selection" : "Pick an Image"}
                </label>
              </div>

              {/* Shoe Image */}
              {logoImage && (
                <div className="d-flex align-items-center justify-content-center w-100">
                  <img
                    src={logoImageFile ? logoImage : imageUrl(logoImage)}
                    alt="logoImage"
                    className="w-50 mt-3"
                    style={{
                      width: "200px !important",
                    }}
                  />
                </div>
              )}
            </div>

            {/* Logo 2 */}
            <div className="col-md-5">
              <div class="form-group" className="seo__imagePicker mt-3">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  name="uploadfile"
                  id={"logo2"}
                  style={{
                    display: "none",
                  }}
                  onChange={(event) => {
                    handleSelectImage(event, setLogo2ImageFile, setLogo2Image);
                  }}
                />
                <label for="exampleInputEmail1">White Logo</label>
                <label
                  for={"logo2"}
                  className="btn btn-primary btn-lg btn-block"
                  style={{
                    backgroundColor: green[500],
                  }}
                  onChange={(e) => {
                    // console.log(e.target.files[0]);
                  }}
                >
                  {logo2Image ? "Change selection" : "Pick an Image"}
                </label>
              </div>

              {/* Shoe Image */}
              {logo2Image && (
                <div className="d-flex align-items-center justify-content-center w-100">
                  <img
                    src={logo2ImageFile ? logo2Image : imageUrl(logo2Image)}
                    alt="logo2Image"
                    className="w-50 mt-3"
                    style={{
                      width: "200px !important",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="cms-btn-container">
              <Button
                className="update-btn"
                onClick={handleSubmit}
                disabled={isApiCall}
              >
                {isApiCall ? "Please Wait..." : "Submit"}
              </Button>
            </div>
          </>
        )}
      </section>
    </ScreenBoiler>
  );
};

export default HeaderCmsPage;
