import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import { Get } from "../Axios/AxiosFunctions";
import { BaseURL } from "../Config/apiUrl";
import ScreenBoiler from "../components/ScreenBoiler";
import TableRowsLoader from "../components/TableRowsLoader";

function CMSScreen() {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  // get Item handler
  const getData = async () => {
    const url = BaseURL(`cms/page/all?all=true`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.pages);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>CMS</h1>
        </div>

        <div className="fieldSetMainContainer mt-3">
          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">
                            {item?.pageName?.replaceAll("_", " ")}
                          </td>

                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {}}
                              >
                                <Button
                                  className="update-btn"
                                  onClick={() => {
                                    if(item?.pageName?.toLowerCase() === "header"){
                                      return navigate(`/content/header?id=${item?._id}`);
                                    }
                                    navigate(
                                      `/content?identifier=${item?.pageName}&id=${item?._id}`
                                    );
                                  }}
                                >
                                  Edit
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </section>
    </ScreenBoiler>
  );
}

export default CMSScreen;
